import React from "react"

import styles from "./index.module.css";
import  family_office_01  from "../../images/family_office_01.svg";
import  financial_advisor_01  from "../../images/financial_advisor_01.svg";
import { escapeRegExp } from "../../common/gFunctions";
import DropDown from "../../shared/dropDown"
import { LinkType } from "../../common/constants";
import Tooltip from "../../shared/Tooltip";
import ProceedButton from "../proceedButton";

class Import extends React.Component {

    constructor(props){
        super(props);

        var expanded = this.getAllExpanded();

        const categDropdownOptions = this.getCategDropdownOptions();

        this.state = {
            searchTerm: '',
            selectedCategories: this.props.categories,
            filteredCategories: this.props.categories,
            categoryFilter: categDropdownOptions[0],
            categoryListLength: 30,
            categoryListLengthMobile: 10,
            expanded,
            categDropdownOptions
        }

        this.searchInput = React.createRef();
        this.importListStart = React.createRef();
        this.searchContainer = React.createRef();
    }

    focusSearchInput = () => {
        if (this.searchInput && this.searchInput.current) {
            this.searchInput.current.focus();
            window.scrollTo({ behavior: 'smooth', top: this.searchContainer.current.offsetTop-82 });
        }
    }

    onChangeSearchTerm = (e) => {
        const value = e.target.value;
        this.scrollToImportTop();
        const filteredCategories = this.filterCategories(value,this.state.selectedCategories,this.state.filteredCategories);
        this.setState({
            [e.target.name]: value,
            filteredCategories
        })
    }

    scrollToImportTop = () => {
        this.importListStart.current.scrollIntoView();
        window.scrollBy(0, -182)
    }

    getAllExpanded = (state = false) => {
        var expanded = {};

        this.props.categories.forEach((category) => {
            expanded[category.Id] = state
        })

        return expanded;
    }

    filterCategories = (term, selectedCategories, prevFilteredCategories, setExpanded = true) => {
        term = term.trim();
        var expanded;
        if (term === '' || !term) {
            if (setExpanded) {
                expanded = this.getAllExpanded();
                this.setState({expanded});
            }
            return selectedCategories;
        } else {
            if (setExpanded) {
                expanded = this.getAllExpanded(true);
                this.setState({expanded});
            }
            const input_list = new RegExp('^(?=.*'+escapeRegExp(term).toLowerCase().split(/\s+/).join(')(?=.*')+')','i');
            const startCategories = term.indexOf(this.state.searchTerm)>-1 ? prevFilteredCategories : selectedCategories;
            const filteredCategories = startCategories.map((categ) => {
                return {
                    ...categ,
                    list: categ.list.filter((item) => input_list.test(item.node.Name))
                }
            });

            return filteredCategories.filter((categ) => categ.list.length>0);
        }
    }

    categoryFilterCallBack = (item,name) => {

        var selectedCategories;
        var expanded;
        if (item.id === 0) {
            expanded = this.getAllExpanded();
            selectedCategories = this.props.categories;
        } else {
            selectedCategories = [this.props.categories.find((categ) => categ.Id === item.id)];
            expanded = this.getAllExpanded(true);
        }

        var filteredCategories = this.filterCategories(this.state.searchTerm,selectedCategories,selectedCategories,false);

        this.setState({
            selectedCategories,
            filteredCategories,
            categoryFilter: item,
            expanded
        });
    }

    onClickViewAll = (id) => {
        this.setState({
            expanded: {
                ...this.state.expanded,
                [id]: true
            }
        })
    }

    clearFilters = () => {
        var expanded = this.getAllExpanded(false);
        this.setState({
            searchTerm: '',
            selectedCategories: this.props.categories,
            filteredCategories: this.props.categories,
            categoryFilter: this.state.categDropdownOptions[0],
            expanded
        })
    }

    getCategDropdownOptions = () => {
        var categories = this.props.categories.map(categ => {
            return {
                id: categ.Id,
                text: categ.title
            }
        });
        
        return [{id:0,text: 'All'},...categories];
    }

    render() {
        const { importContent } = this.props;

        return(
            <>
                <div className={styles.clients_main_section}>
                    <div className={[styles.top_banner_section,'d-flex','justify-content-center'].join(' ')}>
                        <div className={['align-self-center'].join(' ')}>
                            <div className="align-self-stretch">
                                <div className="container">
                                    <div className={["row", "justify-content-md-center"].join(" ")}>
                                        <div className={[styles.mpr_main_title ,'text-md-center'].join(' ')}>
                                            <h2>{importContent.banner.headline}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mpr_stock_brokers_section}>
                        <div className={styles.mpr_stock_brokers_intro}>
                            <div className="container">
                                <div className={["row", "justify-content-md-center"].join(" ")}>
                                    <div className={["col-md-11", "text-center"].join(" ")}>
                                        <p>{importContent.banner.text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.mpr_stock_brokers}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className={[styles.mpr_stock_broker_box,'text-center','pl-md-5','pr-md-5','mb-5'].join(' ')}>
                                            <img className="mb-3" src={family_office_01} alt="Family Offices"/>
                                            <h4 className="mb-3">{importContent.Investors.headline}</h4>
                                            <p>{importContent.Investors.text}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className={[styles.mpr_stock_broker_box,'text-center','pl-md-5','pr-md-5','mb-5','import-advisors-anchor'].join(' ')}>
                                            <img className="mb-3" src={financial_advisor_01} alt="Advisors"/>
                                            <h4 className="mb-3">{importContent.Advisors.headline}</h4>
                                            <p className={'pre-wrap'}>{importContent.Advisors.text}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className={["row", "justify-content-center"].join(" ")}>
                                {/* <div className={[styles.backgroundCurve1,"d-none","d-md-block"].join(' ')}>
                                    <FPattern/>
                                </div> */}
                                <div className={["col-12", "text-center", "mt-5"].join(" ")}>
                                    <div>
                                        <h4 className={["text-center","mb-4"].join(" ")}>{importContent.autoImport.headline}</h4>
                                        {
                                            importContent.autoImport.paragraphs.map(((autoImportPara, index) => {
                                                return <p key={index}>{autoImportPara.text}</p>
                                            }))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[styles.search_section].join(' ')} ref={this.searchContainer}>
                        <div className="container">
                            <div className="row">
                                <div className={[styles.borderRight,'col-md-5 ', 'col-lg-5','col-8','border-right','d-flex align-items-stretch justify-content-center flex-column'].join(' ')}>
                                    <div className={"d-flex align-items-center flex-grow-1"} onClick={this.focusSearchInput}> 
                                        <span><i className={["fa", "fa-search", "mr-4","fa-lg"].join(" ")} aria-hidden="true"></i></span>
                                        <input className={["border-0",styles.search_input].join(" ")} ref={this.searchInput} type="text" value={this.state.searchTerm} name="searchTerm" onChange={(e) => {this.onChangeSearchTerm(e)}}/>
                                        <span className={styles.searchPlaceholder}>{this.state.searchTerm != "" ? "" : importContent.listSection.search}</span>
                                    </div>
                                </div>
                                <div className={[styles.borderRight,'col-md-4', 'col-lg-5', 'col-4','pt-md-4','pb-md-4','pt-4 pb-4', 'align-self-center', 'd-none','d-md-block'].join(' ')}>
                                    <DropDown title={this.state.categoryFilter.text} mprdropDown={true} data={this.state.categDropdownOptions} classes={[styles.pricingDropDown, styles.border_none].join(" ")} callBack={(item)=>{this.categoryFilterCallBack(item, "filter")}} />
                                </div>
                                <div className={[styles.borderLeft,'col-md-3','col-lg-2','col-4','pt-md-4','pb-md-4','pt-4 pb-4','text-center'].join(' ')}> 
                                    <button className={[styles.btn,'btn', 'btn-primary', 'rounded'].join(' ')} onClick={this.clearFilters}>Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.longtime_section} ref={this.importListStart}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className={styles.mpr_title}>
                                        <h2 className={["mb-4", "text-md-center"].join(" ")}>{importContent.listSection.headline}</h2>
                                        <p className="text-md-center">
                                            {importContent.listSection.text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={[styles.longtime_list,'d-none','d-md-block'].join(' ')}>
                            {
                                this.state.filteredCategories.map((category,index) => {
                                    return (
                                        <ImportCategory category={category} key={index} expanded={this.state.expanded[category.Id]} onClickViewAll={this.onClickViewAll} maxLength={this.state.categoryListLength}/>
                                    )
                                })
                            }
                            </div>

                            <div className={["longtime_mobile_list", "d-md-none", "d-block"].join(" ")}>
                                <div className="container">
                                    <div className="row">
                                        <div className={["col-md-12", "mb-2"].join(" ")}>
                                            <DropDown title={this.state.categoryFilter.text}  classes={'mprbtn-Option'} mprdropDown={true} data={this.state.categDropdownOptions} callBack={(item)=>this.categoryFilterCallBack(item)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                {
                                                    this.state.filteredCategories.map((category,index) => {
                                                        return (
                                                            <ImportCategory category={category} key={index} expanded={this.state.expanded[category.Id]} onClickViewAll={this.onClickViewAll} maxLength={this.state.categoryListLengthMobile} isMobile={true}/>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const ImportCategory = ({category, expanded, onClickViewAll, maxLength, isMobile=false}) => {
    const list = expanded ? category.list : category.list.slice(0,maxLength);
    return (
        <div className={["row", "pb-md-5", "mb-md-5",styles.mb_0,isMobile ? styles.mbCategContainer : ""].join(" ")}>
            <div className={["col-md-3 pr-md-5",isMobile ? styles.mbCategTitle : ""].join(" ")}>
                <h4>{category.title}</h4>
            </div>
            <div className="col-md-9">
                <div className="row">
                {
                    list.map(( item, index ) => (
                        
                        <div className={["col-md-4", "d-flex"].join(" ")} key={index}>
                            <div className={[styles.list_item,'w-100'].join(' ')}>
                                {item.node.Name}
                                {
                                    item.node.LinkType === LinkType.DOWNLOAD && item.node.Link !== "" && item.node.Link &&
                                    <>
                                    <a href={item.node.Link} target="_blank" rel="noopener noreferrer" id={'icon-'+item.node.Id} className={styles.fileIconContainer}><i className={["fa", "fa-file-text-o",styles.fileIcon].join(" ")} aria-hidden="true"></i></a>
                                    <Tooltip text={"Click to download the sample file"} target={'icon-'+item.node.Id}/>
                                    </>
                                }
                            </div>
                        </div>
                    ))
                }
                {!expanded && category.list.length > maxLength ?  
                <>
                <div className={["col-md-4", "d-flex"].join(" ")}></div> 
                <div className={["col-md-4", "d-flex","justify-content-center"].join(" ")}>
                    <div className={styles.viewAllContainer}>
                        <ProceedButton buttonText={'View All'} onClick={() => {onClickViewAll(category.Id)}} borderRadius={3}/>
                    </div>
                </div> 
                </>
                : ""}
                </div>
            </div>
        </div>
    )
}


export default Import
