import React from "react"

import Layout from "../components/flayout"
import MImport from "../components/import"

const Page = ({data}) => {

  const categories = data.allImportCateg.edges.map((category) => {
    return {
      id: category.node.id,
      Id: category.node.Id,
      title: category.node.Name,
      list: data.allImport.edges.filter((item) => {
        return item.node.Category.Id === category.node.Id
      })
    }
  });

  return (
    <Layout darkHeader={true} seoFields={seoFields}>
      <MImport categories={categories} importContent={data.allContentJson.edges[0].node.importContent}/>
    </Layout>
  )
}

const seoFields = {
  description: "Seamlessly import your Stock & Mutual Fund transaction files & statements as well as your bank statements across 700+ brokers and 60+ banks. Save time with MProfit by emilinating tedious and manual data entry.",
  // lang,
  // meta,
  // keywords,
  title: "Import" 
}

export const query = graphql`
query importListQuery{
  allImport(sort:{fields:[Name],order:ASC}){
    edges {
      node {
        Name
        Id
        Link
        LinkType
        Category {
          Id
        }
      }
    }
  }
  allImportCateg(sort:{fields:[DisplayOrder], order:ASC}){
    edges {
      node {
        Name
        Id
      }
    }
  }
  allContentJson {
    edges {
      node {
        clientType
        importContent {
          banner {
            headline
            text
          }
          autoImport {
            headline
            paragraphs {
              text
            }
          }
          listSection {
            headline
            search
            text
          }
          Investors {
            headline
            text
          }
          Advisors {
            headline
            text
          }
        }
      }
    }
  }
}
`

export default Page

